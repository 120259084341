<template>
  <div class="py-8 px-28">
    <h1 class="pb-3 mb-6">Slicer help</h1>
    <div class="mb-12">
      <h2>Installation guide</h2>
      <div class="border-b border-primary pb-3 mb-6">Guide to install Cura slicer with optimized Rapid Dimension settings and connectivity to the Rapid Cloud.</div>
      <div class="rounded-lg border border-primary p-8">
        <ol class="list-decimal space-y-4">
          <li>
            <a href="https://github.com/Ultimaker/Cura/releases/download/4.13.1/Ultimaker_Cura-4.13.1-amd64.exe" target="_blank" class="underline">
              Download Cura client v4.13.1
            </a>
          </li>
          <li>
            <a href="/RapidDimensionCloud-0.2.2.zip" target="_blank" class="underline">
              Download the Rapid Dimension plugin
            </a>
          </li>
          <li>Extract <b>RapidDimensionCloud.zip</b> downloaded in step 2 to a <b>RapidDimensionCloud</b> folder</li>
          <li><b>Install</b> Cura using the Cura-4.13.1.exe downloaded in step 1</li>
          <li><b>Start</b> Cura</li>
          <li>Complete the "Welcome setup"-guide. If asked to add a printer, <b>add any random printer</b>, for example Non-networked printer -> Custom -> Custom FFF Printer (we will delete this later)</li>
          <li>In Cura in the top menu bar go to <b>Help -> Show configuration folder</b></li>
          <li>In the configuration folder enter the <b>plugins</b> folder and <b>paste</b> the folder extracted in step 3.</li>
          <li><b>Restart</b> Cura</li>
          <li>Add new Rapid printer by going to <b>Settings -> Printers -> Add Printer</b>. Open "Add a non-networked printer" and select Rapid Dimension and Rapid One</li>
          <li>(optional) <b>Delete</b> the Custom printer added in step 6</li>
          <li>Your are now ready to start slicing!</li>
        </ol>
      </div>
    </div>
    <div class="mb-12">
      <h2>Usage guide</h2>
      <div class="border-b border-primary pb-3 mb-6">How to slice and upload objects to the Rapid Cloud</div>
      <div class="rounded-lg border border-primary p-8">
        <h4 class="mb-2">Notes on preparing files:</h4>
        <ul class="list-disc space-y-4 mb-12">
          <li>When preparing files make sure you are using a printer from Rapid Dimension (see the installation steps)</li>
          <li>When selecting materials make sure you use materials from Rapid Dimension. The optimized settings will then be used when slicing the object.</li>
        </ul>
        <h4 class="mb-2">How to upload:</h4>
        <ol class="list-decimal space-y-4">
          <li>When you are done preparing, press the Slice-button</li>
          <li>When it is sliced and it is your first time, the default button/action is "Save to disk". Next to it is a down arrow, press it and select "Upload to R-D"</li>
          <li>If it is your first time, you need to login</li>
          <li>After you have logged in, you can either upload it as a new object in the library or update/replace an old object</li>
          <li>If new object, name it and optionally assign it to a category.</li>
          <li>Upload!</li>
        </ol>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'SlicerInstallation',
}
</script>